import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 링크 플러스 아이콘
 * @param props
 * @returns
 */
export function LinkPlusIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.87775 3.00001C8.87775 1.95066 9.72841 1.10001 10.7778 1.10001H13.2222C14.2715 1.10001 15.1222 1.95067 15.1222 3.00001V8.77778C15.1222 8.83301 15.167 8.87778 15.2222 8.87778H21C22.0493 8.87778 22.9 9.72844 22.9 10.7778V13.2222C22.9 14.2716 22.0493 15.1222 21 15.1222H16.4444V13.3222H21C21.0552 13.3222 21.1 13.2775 21.1 13.2222V10.7778C21.1 10.7226 21.0552 10.6778 21 10.6778H15.2222C14.1729 10.6778 13.3222 9.82713 13.3222 8.77778V3.00001C13.3222 2.94478 13.2774 2.90001 13.2222 2.90001H10.7778C10.7225 2.90001 10.6778 2.94478 10.6778 3.00001V11C10.6778 11.0552 10.7225 11.1 10.7778 11.1H13.2222C14.2715 11.1 15.1222 11.9507 15.1222 13V21C15.1222 22.0493 14.2715 22.9 13.2222 22.9H10.7778C9.72841 22.9 8.87775 22.0493 8.87775 21V15.2222C8.87775 15.167 8.83298 15.1222 8.77775 15.1222H2.99998C1.95064 15.1222 1.09998 14.2716 1.09998 13.2222V10.7778C1.09998 9.72844 1.95063 8.87778 2.99998 8.87778H7.55553V10.6778H2.99998C2.94475 10.6778 2.89998 10.7226 2.89998 10.7778V13.2222C2.89998 13.2775 2.94475 13.3222 2.99998 13.3222H8.77775C9.8271 13.3222 10.6778 14.1729 10.6778 15.2222V21C10.6778 21.0552 10.7225 21.1 10.7778 21.1H13.2222C13.2774 21.1 13.3222 21.0552 13.3222 21V13C13.3222 12.9448 13.2774 12.9 13.2222 12.9H10.7778C9.72841 12.9 8.87775 12.0493 8.87775 11V3.00001Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default LinkPlusIcon;
