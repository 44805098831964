import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Plus 아이콘
 * @param props
 * @returns
 */
export function PlusIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5713 13.2715C19.9579 13.2715 20.2713 12.9581 20.2713 12.5715C20.2713 12.1849 19.9579 11.8715 19.5713 11.8715L13.2002 11.8715L13.2002 5.50045C13.2002 5.11385 12.8868 4.80045 12.5002 4.80045C12.1136 4.80045 11.8002 5.11385 11.8002 5.50045L11.8002 11.8715L5.42918 11.8715C5.04258 11.8715 4.72918 12.1849 4.72918 12.5715C4.72918 12.9581 5.04258 13.2715 5.42918 13.2715L11.8002 13.2715V19.6426C11.8002 20.0292 12.1136 20.3426 12.5002 20.3426C12.8868 20.3426 13.2002 20.0292 13.2002 19.6426V13.2715L19.5713 13.2715Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default PlusIcon;
