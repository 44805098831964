import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * FolderRefund 아이콘
 * @param props
 * @returns
 */
export function FolderRefundIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.27259 3.77262C2.86333 3.18188 3.66454 2.85001 4.49998 2.85001H9.87897C10.1943 2.85007 10.5067 2.91228 10.798 3.03308C11.0891 3.1538 11.3536 3.33068 11.5764 3.55361C11.5762 3.55348 11.5765 3.55374 11.5764 3.55361L13.6964 5.67361C13.8082 5.78562 13.9618 5.84976 14.1214 5.85001H19.5C20.3354 5.85001 21.1366 6.18188 21.7274 6.77262C22.3181 7.36336 22.65 8.16457 22.65 9.00001V19C22.65 19.8354 22.3181 20.6367 21.7274 21.2274C21.1366 21.8181 20.3354 22.15 19.5 22.15H4.49998C3.66454 22.15 2.86333 21.8181 2.27259 21.2274C1.68185 20.6367 1.34998 19.8354 1.34998 19V6.00001C1.34998 5.16457 1.68185 4.36336 2.27259 3.77262ZM4.49998 4.65001C4.14193 4.65001 3.79856 4.79224 3.54538 5.04541C3.29221 5.29859 3.14998 5.64196 3.14998 6.00001V19C3.14998 19.358 3.29221 19.7014 3.54538 19.9546C3.79856 20.2078 4.14193 20.35 4.49998 20.35H19.5C19.858 20.35 20.2014 20.2078 20.4546 19.9546C20.7077 19.7014 20.85 19.358 20.85 19V9.00001C20.85 8.64196 20.7077 8.29859 20.4546 8.04541C20.2014 7.79224 19.858 7.65001 19.5 7.65001H14.121C13.4855 7.64945 12.8736 7.39682 12.4236 6.9464C12.4234 6.94627 12.4237 6.94653 12.4236 6.9464L10.3036 4.8264C10.2479 4.77062 10.1813 4.72597 10.1085 4.69577C10.0357 4.6656 9.95774 4.65005 9.87897 4.65001C9.87891 4.65001 9.87904 4.65001 9.87897 4.65001H4.49998Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8156 8.43433C11.1281 8.74675 11.1281 9.25328 10.8156 9.5657L9.93132 10.45H12.875C13.7833 10.45 14.6545 10.8109 15.2968 11.4532C15.9391 12.0955 16.3 12.9666 16.3 13.875C16.3 14.7834 15.9391 15.6545 15.2968 16.2969C14.6545 16.9392 13.7833 17.3 12.875 17.3H11.75C11.3081 17.3 10.95 16.9418 10.95 16.5C10.95 16.0582 11.3081 15.7 11.75 15.7H12.875C13.359 15.7 13.8232 15.5077 14.1654 15.1655C14.5077 14.8232 14.7 14.359 14.7 13.875C14.7 13.391 14.5077 12.9268 14.1654 12.5845C13.8232 12.2423 13.359 12.05 12.875 12.05H9.93132L10.8156 12.9343C11.1281 13.2467 11.1281 13.7533 10.8156 14.0657C10.5032 14.3781 9.99669 14.3781 9.68427 14.0657L7.43427 11.8157C7.12185 11.5033 7.12185 10.9967 7.43427 10.6843L9.68427 8.43433C9.99669 8.12191 10.5032 8.12191 10.8156 8.43433Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default FolderRefundIcon;
