import { IconContainer } from '../IconContainer';
import type { Props } from '../IconContainer';

/**
 * 도움말 아이콘
 * @param props
 * @returns
 */
export function PowerOnOffIcon(props: Props) {
  return (
    <IconContainer {...props}>
      <path
        d="M4.6967 4.6967C1.76777 7.62563 1.76777 12.3744 4.6967 15.3033C7.62563 18.2322 12.3744 18.2322 15.3033 15.3033C18.2322 12.3744 18.2322 7.62563 15.3033 4.6967M10 2.5V10"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default PowerOnOffIcon;
