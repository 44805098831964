import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 이관 아이콘
 * @param props
 * @returns
 */
export function DeleteAttachFileIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M0.5 0.5L13.5 13.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 3L7.46436 1.53553C8.84507 0.154823 11.0836 0.154823 12.4644 1.53553C13.8451 2.91625 13.8451 5.15482 12.4644 6.53554L11 8"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99989 11L6.53553 12.4645C5.15482 13.8452 2.91625 13.8452 1.53553 12.4645C0.154822 11.0838 0.154823 8.84518 1.53553 7.46446L2.99989 6"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5L7 7"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 9L5.5 8.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default DeleteAttachFileIcon;
