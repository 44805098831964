import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';

/**
 * ButtonPrimitive.Label 컴포넌트 속성
 */
export interface Props {
  /** @type [asChild=false] */
  asChild?: boolean;
  className?: string;
}

/**
 * ButtonPrimitive.Label 컴포넌트
 * @param props
 */
export function Label({
  children,
  asChild = false,
  className,
}: React.PropsWithChildren<Props>) {
  const Comp = asChild ? Slot : 'span';

  return <Comp className={clsx('shrink-0', className)}>{children}</Comp>;
}

export default Label;
