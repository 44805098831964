import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * XMark 아이콘
 * @param props
 * @returns
 */
export function XMarkIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M19.0002 4.99994L5.00021 18.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00021 4.99994L19.0002 18.9999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default XMarkIcon;
