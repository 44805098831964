import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 검색창 초기화 아이콘
 * @param props
 * @returns
 */
export function SearchClearIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM14.781 15.2497L12.0001 12.4689L9.21899 15.25L8.51189 14.5429L11.293 11.7618L8.41699 8.88574L9.1241 8.17864L12.0001 11.0547L14.8758 8.17893L15.583 8.88604L12.7072 11.7618L15.4881 14.5426L14.781 15.2497Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default SearchClearIcon;
