import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';

/**
 * 버튼 태그 타입
 */
export type TagTypes = 'div' | 'button' | 'a';

/**
 * ButtonPrimitives.Root > Props 컴포넌트 속성
 */
export interface Props {
  /** 버튼에 사용되는 태그이름 (기본값: 'div') */
  as?: TagTypes;
  /** 버튼 타입 (기본값: button) */
  type?: 'button' | 'submit' | 'reset';
  asChild?: boolean;
  /** 버튼 비활성화 여부 (기본값: false) */
  disabled?: boolean;
  /** 버튼 토글 상태 (기본값: false) */
  pressed?: boolean;
  /** tab-index 값 (기본값: 0) */
  tabIndex?: number;
  /** aria-haspopup 속성. Variation 확장용 */
  ariaHasPopup?: boolean;
  /** aria-controls 속성. Variation 확장용 */
  ariaControls?: string;
  /** aria-expanded 속성. Variation 확장용 */
  ariaExpanded?: boolean;
  className?: string;
  style?: React.CSSProperties;

  /** @property 클릭 이벤트 핸들러 */
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  /** @deprecated */
  title?: string;
}

/**
 * Button 컴포넌트
 * @returns
 */
export function Root({
  as = 'button',
  type = 'button',
  asChild = false,
  disabled = false,
  pressed = false,
  tabIndex = 0,
  ariaHasPopup,
  ariaControls,
  ariaExpanded,
  className,
  children,
  ...restProps
}: React.PropsWithChildren<Props>) {
  const Comp = asChild ? Slot : as;

  return (
    <Comp
      type={type}
      className={className}
      disabled={disabled}
      tabIndex={tabIndex}
      role={as !== 'button' ? 'button' : undefined}
      aria-pressed={pressed === true ? true : undefined}
      aria-disabled={disabled === true ? true : undefined}
      aria-haspopup={ariaHasPopup ? 'true' : undefined}
      aria-controls={ariaControls ? ariaControls : undefined}
      aria-expanded={ariaExpanded ? 'true' : undefined}
      {...restProps}
    >
      {children}
    </Comp>
  );
}

export default Root;
