import { clsx } from 'clsx';
import type { PropsWithChildren } from 'react';
import * as ButtonPrimitives from './primitives';

import { ColorSetTypes, ShapeTypes, SizeTypes, StyleTypes } from './types';

import styles from './style.module.css';

// ButtonPrimitives의 주요 타입을 re-export
export type TagTypes = ButtonPrimitives.TagTypes;

/**
 * Button 컴포넌트 속성
 */
export interface Props extends ButtonPrimitives.RootProps {
  /** (미리 정해진) 버튼 스타일 (solid | outline | ghost) */
  variant?: StyleTypes;
  /** (미리 정해진) 버튼 색 테마 */
  colorset?: ColorSetTypes;
  /** (미리 정해진) 버튼 형태 */
  shape?: ShapeTypes;
  /** (미리 정의된) 버튼 크기 (기본값 undefined) */
  size?: SizeTypes;
  /** (미리 정의된) 버튼 폭 자동 지정 (기본값 auto) */
  autosize?: 'auto' | 'full';
  /** @deprecated Use variant */
  styleType?: StyleTypes;
}

/**
 * Button 컴포넌트
 * @returns
 */
export function Button({
  children,
  as = 'button',
  shape,
  size,
  autosize = 'auto',
  colorset,
  variant,
  className,
  disabled = false,
  styleType,
  ...props
}: PropsWithChildren<Props>) {
  const btnVariant = variant || styleType;
  const btnColor = fixColorsetClass(colorset);
  const btnSize = fixSizeClass(size);

  return (
    <ButtonPrimitives.Root
      as={as}
      className={clsx(
        styles.Button,
        {
          [`${styles.solid}`]: btnVariant === 'solid',
          [`${styles.outline}`]: btnVariant === 'outline',
          [`${styles.ghost}`]: btnVariant === 'ghost',
        },
        {
          [`${styles.colorLevel1}`]: btnColor === 'level1',
          [`${styles.colorLevel2}`]: btnColor === 'level2',
        },
        {
          [`${styles.small}`]: btnSize === 'sm',
          [`${styles.medium}`]: btnSize === 'md',
          [`${styles.large}`]: btnSize === 'lg',
          [`${styles.fullWidth}`]: autosize === 'full',
          [`${styles.autoWidth}`]: autosize === 'auto',
        },
        {
          [`${styles.rect}`]: shape === 'rect',
          [`${styles.round}`]: shape === 'round',
        },
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </ButtonPrimitives.Root>
  );
}

function fixColorsetClass(colorset?: ColorSetTypes) {
  if (colorset === 'major') {
    return 'level1';
  }
  if (colorset === 'minor') {
    return 'level2';
  }

  return colorset;
}

function fixSizeClass(size?: SizeTypes) {
  if (size === 'small') {
    return 'sm';
  }
  if (size === 'medium') {
    return 'md';
  }
  if (size === 'large') {
    return 'lg';
  }
  if (size === 'none') {
    return undefined;
  }

  return size;
}

export default Button;
