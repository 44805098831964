import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Magnifying 아이콘
 * @param props
 * @returns
 */
export function MagnifyingIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M21 21L15.803 15.803M15.803 15.803C17.2096 14.3964 17.9998 12.4887 17.9998 10.4995C17.9998 8.5103 17.2096 6.60258 15.803 5.196C14.3964 3.78942 12.4887 2.99922 10.4995 2.99922C8.51029 2.99922 6.60256 3.78942 5.19599 5.196C3.78941 6.60258 2.99921 8.5103 2.99921 10.4995C2.99921 12.4887 3.78941 14.3964 5.19599 15.803C6.60256 17.2096 8.51029 17.9998 10.4995 17.9998C12.4887 17.9998 14.3964 17.2096 15.803 15.803Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default MagnifyingIcon;
