import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 로그아웃 아이콘
 * @param props
 * @returns
 */
export function LogOutIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        id="Icon"
        d="M15.2198 20.9004L18.9257 20.9004C19.4873 20.9004 20.026 20.6791 20.4231 20.2853C20.8202 19.8915 21.0433 19.3573 21.0433 18.8004L21.0433 6.20039C21.0433 5.64344 20.8202 5.10929 20.4231 4.71547C20.026 4.32164 19.4873 4.10039 18.9257 4.10039L15.2198 4.10039M14.9566 12.5004L2.95662 12.5004M2.95662 12.5004L7.54177 17.3004M2.95662 12.5004L7.54177 7.70039"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default LogOutIcon;
