import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Inbox 아이콘
 * @param props
 * @returns
 */
export function InboxIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M2.25 13.5H6.11C6.5278 13.5001 6.93731 13.6165 7.29267 13.8363C7.64803 14.056 7.9352 14.3703 8.122 14.744L8.378 15.256C8.56488 15.6299 8.8522 15.9443 9.20775 16.164C9.5633 16.3837 9.97303 16.5001 10.391 16.5H13.609C14.027 16.5001 14.4367 16.3837 14.7922 16.164C15.1478 15.9443 15.4351 15.6299 15.622 15.256L15.878 14.744C16.0649 14.3701 16.3522 14.0557 16.7078 13.836C17.0633 13.6163 17.473 13.4999 17.891 13.5H21.75M2.25 13.838V18C2.25 18.5967 2.48705 19.169 2.90901 19.591C3.33097 20.0129 3.90326 20.25 4.5 20.25H19.5C20.0967 20.25 20.669 20.0129 21.091 19.591C21.5129 19.169 21.75 18.5967 21.75 18V13.838C21.75 13.614 21.716 13.391 21.65 13.177L19.24 5.338C19.0985 4.87824 18.8133 4.47595 18.4264 4.19015C18.0394 3.90435 17.5711 3.75009 17.09 3.75H6.911C6.42995 3.75009 5.96159 3.90435 5.57464 4.19015C5.1877 4.47595 4.90254 4.87824 4.761 5.338L2.35 13.177C2.28394 13.3911 2.25023 13.6139 2.25 13.838Z"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default InboxIcon;
