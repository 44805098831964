import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * NumberedList 아이콘
 * @param props
 * @returns
 */
export function NumberedListIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28699 3.30711C6.56725 3.4232 6.74998 3.69668 6.74998 4.00002V10C6.74998 10.4142 6.41419 10.75 5.99998 10.75C5.58576 10.75 5.24998 10.4142 5.24998 10V5.81068L4.53031 6.53035C4.23741 6.82325 3.76254 6.82325 3.46965 6.53035C3.17675 6.23746 3.17675 5.76259 3.46965 5.46969L5.46965 3.46969C5.68415 3.25519 6.00673 3.19103 6.28699 3.30711ZM11 6.75002C10.5858 6.75002 10.25 6.41424 10.25 6.00002C10.25 5.58581 10.5858 5.25002 11 5.25002H20C20.4142 5.25002 20.75 5.58581 20.75 6.00002C20.75 6.41424 20.4142 6.75002 20 6.75002H11ZM10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H20C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75H11C10.5858 12.75 10.25 12.4142 10.25 12ZM5.99998 14.75C5.66846 14.75 5.35051 14.8817 5.11609 15.1161C4.88167 15.3506 4.74998 15.6685 4.74998 16C4.74998 16.4142 4.41419 16.75 3.99998 16.75C3.58576 16.75 3.24998 16.4142 3.24998 16C3.24998 15.2707 3.53971 14.5712 4.05543 14.0555C4.57116 13.5398 5.27063 13.25 5.99998 13.25C6.72932 13.25 7.4288 13.5398 7.94452 14.0555C8.46025 14.5712 8.74998 15.2707 8.74998 16C8.74998 16.5278 8.52035 16.9381 8.27721 17.2475C8.1008 17.472 7.87111 17.6964 7.67798 17.8851C7.62545 17.9364 7.57562 17.985 7.53031 18.0304C7.51428 18.0464 7.49753 18.0617 7.48012 18.0762L6.07152 19.25H7.99998C8.41419 19.25 8.74998 19.5858 8.74998 20C8.74998 20.4142 8.41419 20.75 7.99998 20.75H3.99998C3.68421 20.75 3.40227 20.5522 3.29477 20.2553C3.18728 19.9584 3.27726 19.626 3.51984 19.4239L6.49399 16.9454C6.56205 16.8776 6.62448 16.8165 6.68212 16.76C6.85695 16.5889 6.9877 16.4608 7.09775 16.3208C7.22961 16.153 7.24998 16.0633 7.24998 16C7.24998 15.6685 7.11828 15.3506 6.88386 15.1161C6.64944 14.8817 6.3315 14.75 5.99998 14.75ZM11.25 18C11.25 17.5858 11.5858 17.25 12 17.25H20C20.4142 17.25 20.75 17.5858 20.75 18C20.75 18.4142 20.4142 18.75 20 18.75H12C11.5858 18.75 11.25 18.4142 11.25 18Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default NumberedListIcon;
