import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * ChevronRight 아이콘
 * @param props
 * @returns
 */
export function ChevronLeftIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3864 3.86358C16.7379 4.21505 16.7379 4.7849 16.3864 5.13637L9.5228 12L16.3864 18.8636C16.7379 19.2151 16.7379 19.7849 16.3864 20.1364C16.0349 20.4878 15.4651 20.4878 15.1136 20.1364L7.61361 12.6364C7.26214 12.2849 7.26214 11.7151 7.61361 11.3636L15.1136 3.86358C15.4651 3.51211 16.0349 3.51211 16.3864 3.86358Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ChevronLeftIcon;
