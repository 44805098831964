import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 캘린더 앱 아이콘
 * @param props
 * @returns
 */
export function CalendarIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M7.14286 7.90176H16.7812M6.48512 2.4375V4.07698M17.3125 2.4375V4.07678M20.5 7.07678L20.5 18.5625C20.5 20.2194 19.1569 21.5625 17.5 21.5625H6.5C4.84315 21.5625 3.5 20.2194 3.5 18.5625V7.07678C3.5 5.41992 4.84315 4.07678 6.5 4.07678H17.5C19.1569 4.07678 20.5 5.41992 20.5 7.07678Z"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default CalendarIcon;
