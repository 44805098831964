import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 이관 아이콘
 * @param props
 * @returns
 */
export function FolderUpIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        id="Icon"
        d="M11.6872 16.4256L3.977 16.4256C2.87242 16.4256 1.977 15.5302 1.97701 14.4256L1.97708 6.82201C1.97709 6.06041 1.9768 4.97563 1.97656 4.19017C1.97639 3.63774 2.42417 3.19043 2.9766 3.19043H7.74178L10.046 5.65179H16.9762C17.5285 5.65179 17.9762 6.09951 17.9762 6.65179V9.30811M14.0211 13.8436L16.0664 11.8081M16.0664 11.8081L18.0211 13.7516M16.0664 11.8081V16.8081"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconContainer>
  );
}

export default FolderUpIcon;
