import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 드래그 아이콘
 * @param props
 * @returns
 */
export function DragIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <g transform="translate(5, 7)">
        <path d="M0 1H14" stroke="black" />
        <path d="M0 5H14" stroke="black" />
        <path d="M0 9H14" stroke="black" />
      </g>
    </IconContainer>
  );
}

export default DragIcon;
