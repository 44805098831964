import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 사용 불가 햄버거 메뉴 아이콘
 * @param props
 * @returns
 */
export function DisabledHamburgerMenuIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M8.17319 19.4032C7.74273 19.1547 7.59524 18.6042 7.84377 18.1738L14.9438 5.87622C15.1923 5.44575 15.7427 5.29827 16.1732 5.54679C16.6037 5.79532 16.7511 6.34575 16.5026 6.77622L9.40261 19.0738C9.15409 19.5042 8.60365 19.6517 8.17319 19.4032Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1876 16.65H18.014C18.5111 16.65 18.914 16.2471 18.914 15.75C18.914 15.2529 18.5111 14.85 18.014 14.85H13.2269L12.1876 16.65ZM16.0847 9.90001H18.014C18.5111 9.90001 18.914 9.49706 18.914 9.00001C18.914 8.50295 18.5111 8.10001 18.014 8.10001H17.124L16.0847 9.90001ZM15.7383 8.10001L14.6991 9.90001H12.6206L13.6599 8.10001H15.7383ZM12.2742 8.10001H5.98597C5.48891 8.10001 5.08597 8.50295 5.08597 9.00001C5.08597 9.49706 5.48891 9.90001 5.98597 9.90001H11.235L12.2742 8.10001ZM8.37712 14.85H5.98597C5.48891 14.85 5.08597 15.2529 5.08597 15.75C5.08597 16.2471 5.48891 16.65 5.98597 16.65H7.33789L8.37712 14.85ZM10.802 16.65H8.72353L9.76276 14.85H11.8412L10.802 16.65Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default DisabledHamburgerMenuIcon;
