import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * ExclamationCircleIcon
 * @param props
 * @returns
 */
export function ExclamationCircleIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.89998C9.85172 3.89998 7.79146 4.75337 6.27241 6.27241C4.75337 7.79146 3.89998 9.85172 3.89998 12C3.89998 13.0637 4.10949 14.117 4.51655 15.0997C4.92361 16.0824 5.52026 16.9754 6.27241 17.7275C7.02457 18.4797 7.9175 19.0763 8.90024 19.4834C9.88298 19.8905 10.9363 20.1 12 20.1C13.0637 20.1 14.117 19.8905 15.0997 19.4834C16.0824 19.0763 16.9754 18.4797 17.7275 17.7275C18.4797 16.9754 19.0763 16.0824 19.4834 15.0997C19.8905 14.117 20.1 13.0637 20.1 12C20.1 9.85172 19.2466 7.79146 17.7275 6.27241C16.2085 4.75337 14.1482 3.89998 12 3.89998ZM4.99962 4.99962C6.85623 3.14301 9.37433 2.09998 12 2.09998C14.6256 2.09998 17.1437 3.14301 19.0003 4.99962C20.8569 6.85623 21.9 9.37433 21.9 12C21.9 13.3001 21.6439 14.5874 21.1464 15.7885C20.6489 16.9897 19.9196 18.081 19.0003 19.0003C18.081 19.9196 16.9897 20.6489 15.7885 21.1464C14.5874 21.6439 13.3001 21.9 12 21.9C10.6999 21.9 9.41253 21.6439 8.21141 21.1464C7.01029 20.6489 5.91892 19.9196 4.99962 19.0003C4.08032 18.081 3.35109 16.9897 2.85357 15.7885C2.35605 14.5874 2.09998 13.3001 2.09998 12C2.09998 9.37433 3.14301 6.85623 4.99962 4.99962ZM12 8.09998C12.497 8.09998 12.9 8.50292 12.9 8.99998V12.75C12.9 13.247 12.497 13.65 12 13.65C11.5029 13.65 11.1 13.247 11.1 12.75V8.99998C11.1 8.50292 11.5029 8.09998 12 8.09998ZM11.1 15.75C11.1 15.2529 11.5029 14.85 12 14.85H12.008C12.505 14.85 12.908 15.2529 12.908 15.75V15.758C12.908 16.255 12.505 16.658 12.008 16.658H12C11.5029 16.658 11.1 16.255 11.1 15.758V15.75Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default ExclamationCircleIcon;
