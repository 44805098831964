import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * 오픈북 아이콘
 * @param props
 * @returns
 */
export function AdditionIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props}>
      <path
        d="M19.0713 13.0715C19.5684 13.0715 19.9713 12.6686 19.9713 12.1715C19.9713 11.6745 19.5684 11.2715 19.0713 11.2715L12.9002 11.2715L12.9002 5.10044C12.9002 4.60339 12.4973 4.20044 12.0002 4.20044C11.5032 4.20044 11.1002 4.60339 11.1002 5.10044L11.1002 11.2715L4.92918 11.2715C4.43212 11.2715 4.02918 11.6745 4.02918 12.1715C4.02918 12.6686 4.43212 13.0715 4.92918 13.0715L11.1002 13.0715V19.2426C11.1002 19.7396 11.5032 20.1426 12.0002 20.1426C12.4973 20.1426 12.9002 19.7396 12.9002 19.2426V13.0715L19.0713 13.0715Z"
        fill="currentColor"
      />
    </IconContainer>
  );
}

export default AdditionIcon;
